// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bemiddeling-en-advies-tsx": () => import("./../../../src/pages/bemiddeling-en-advies.tsx" /* webpackChunkName: "component---src-pages-bemiddeling-en-advies-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-missie-tsx": () => import("./../../../src/pages/missie.tsx" /* webpackChunkName: "component---src-pages-missie-tsx" */),
  "component---src-pages-over-mij-tsx": () => import("./../../../src/pages/over-mij.tsx" /* webpackChunkName: "component---src-pages-over-mij-tsx" */),
  "component---src-pages-tarieven-tsx": () => import("./../../../src/pages/tarieven.tsx" /* webpackChunkName: "component---src-pages-tarieven-tsx" */)
}

